
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { countryNoComList } from "@/utils";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerRecommendLogList, getBloggerRecommendLogExport } from "@/api/request/blogger";

//组件
@Component({
  name: "PostRecommendRecord",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private countryList: any[] = [];
  private listLoading: boolean = false;

  //请求数据
  private listQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    time: [],
    user_id_str: "", //被推荐用户
    blogger_id_str: "", //博主ID

    //数据
    country: "",
    blogger_id: 0, //博主ID
    user_id: 0, //被推荐用户ID
    user_nick_name: "", //用户昵称
    blogger_nick_name: "", //博主昵称
    start_time: "", //开始时间
    end_time: "", //结束时间
  };

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = this.listQuery.time[0] + " 00:00:00";
      this.listQuery.end_time = this.listQuery.time[1] + " 23:59:59";
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getBloggerRecommendLogList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理导出
  private async handlerExport() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getBloggerRecommendLogExport(this.listQuery);

    //保存数据
    saveAs(data, "博主帖子推荐记录列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }
}
